<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p class="has-text-grey-light">CO2 emissions grade</p>
      <p class="subtitle is-4">
        {{ data.vehicleExiseDutyData.cO2Band }} &mdash;
        {{ data.vehicleExiseDutyData.cO2Emissions }} g/km
      </p>
    </div>
    <div class="column is-6">
      <p class="has-text-grey-light">
        Fuel cost (estimate) for 12,000 miles
      </p>
      <p v-if="data.combinedMpg && !isElectric" class="subtitle is-4">
        {{ formatMoney(fuelCost) }}
      </p>
      <p v-else class="subtitle is-4 has-text-grey-light">N/A</p>
      <p class="is-size-7">
        A fuel cost figure indicates to the consumer a guide price for
        comparison purposes. This figure is calculated by using the combined
        drive cycle (town centre and motorway) and average fuel price.
        Re-calculated annually, the cost per litre as of
        {{ fuelPrices.dateUpdated }} is as follows - petrol
        {{ fuelPrices.petrol }}p, diesel {{ fuelPrices.diesel }}p and LPG
        {{ fuelPrices.lpg }}p
      </p>
    </div>
    <div class="column is-6">
      <span class="has-text-grey-light">VED for 12 months</span>
      <a
        class="icon is-size-6 has-text-info"
        :title="vedTooltip"
        v-tippy="{ placement: 'right' }"
      >
        <i class="fal fa-info-circle" />
      </a>
      <p class="subtitle is-4">
        {{ formatMoney(data.vehicleExiseDutyData.veD12Months) }}
      </p>
      <p class="is-size-7">
        The standard 12 month VED rate for all registered cars in this band is
        shown for the purposes of comparison. Note, figures quoted reflect the
        current rate only, and may be subject to change in the future. Cars with
        a list price of over £40,000 when new pay an additional rate of £310 per
        year on top of the standard rate, for five years.
      </p>
    </div>
  </div>
</template>

<script>
import { formatMoney } from 'accounting'
import { mapGetters } from 'vuex'
export default {
  name: 'AutoCheckFuel',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('autocheck', ['fuelType', 'fuelPrices', 'fuelCost']),
    isElectric() {
      return this.fuelType === 'ELECTRIC' || this.fuelType === 'HYB-PETROL'
    },
    vedTooltip: () =>
      'Vehicle excise duty (VED) or road tax varies according to the CO2 emissions and fuel type of the vehicle'
  },
  methods: {
    formatMoney(value) {
      return formatMoney(value, '£', 2)
    }
  }
}
</script>
